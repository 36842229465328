.iui-listitem
{
    border: 2px solid transparent;
    color: black;
    list-style-type: none;
    overflow: hidden;
}
.iui-listitem-general
{
    list-style-type: none;
}
.iui-item-drag
{
	height: 0;
	pointer-events: none;
}
.iui-item-drag-above, .iui-item-drag-below
{
	border: thin dotted #cecece;
	height: 38px;
}
.iui-item-drag-open
{
	animation-name: drag-open;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.35s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 

}
@keyframes drag-open
{
	0% { max-height: 0; border-width: 0; opacity: 0; }
	50% { max-height: 0; border-width: 0; opacity: 0; }
	100% { max-height: 38px; border-width: 1; opacity: 1; }
}
.iui-item-drag-close
{
	animation-name: drag-close;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.35s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 

}
@keyframes drag-close
{
	0% { max-height: 38px; border-width: 1; opacity: 1; }
	50% { max-height: 38px; border-width: 1; opacity: 1; }
	100% { max-height: 0; border-width: 0; opacity: 0; }
}
.iui-listitem-hovered
{
	background-color: #f5f5f5;
	border: 2px solid #efefef;
}
.iui-listitem-selected
{
	background-color: #e5e5e5;
	border: 2px solid #dedede;
	z-index: 2;
}
.iui-listitem-focused
{
    border-color: gray;
}
.iui-listitem:focus {
    outline: none !important;
}
.iui-listitem-content
{
    padding: 3px;
}
.iui-listitem-content-hovered
{
}
.iui-listitem-content-selected
{
}
.iui-listitem-content-focused
{
    border-color: gray;
}
.iui-listitem-content:focus {
    outline: none !important;
}

/* Disabled State */
.iui-listitem-disabled div
{
	opacity: 0.75;
	pointer-events: none;
}

/* Animation */
.iui-listitem-animate
{
    border: 0 solid transparent;
    background-color: transparent;
    box-shadow: none;
    list-style-type: none;
    overflow: hidden;
    position: relative;
}
.iui-listitem-animate-enter
{
	animation-name: listitem-animate-enter;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.175s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes listitem-animate-enter
{
	0% { 	   
	    border: thin solid transparent;
	    box-shadow: none;
	}
	100% { 
	    border: thin solid #242424;
	    box-shadow: 2px 3px 3px #cecece;
	}
}
.iui-listitem-animate-select-block
{
	display: block;
	pointer-events: none;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 9;
}
.iui-listitem-animate-select
{
	background: #f5f5f5;
	display: block;
	left: 0;
	position: absolute;
	opacity: 0;
	top: 0;
}
.iui-listitem-animate-select-left
{
	animation-name: listitem-animate-select-left;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes listitem-animate-select-left
{
	0% { 	   
	    left: 100%;
	    opacity: 0.5;
	    width: 0;
	}
	100% { 
	    left: 0;
	    opacity: 0;
	    width: 100%;
	}
}
.iui-listitem-animate-select-right
{
	animation-name: listitem-animate-select-right;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes listitem-animate-select-right
{
	0% { 	   
	    width: 0;
	    opacity: 0.5;
	}
	100% { 
	    width: 100%;
	    opacity: 0;
	}
}
