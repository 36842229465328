.iui-treegrid-block-left, .iui-treegrid-block-right
{
	background: #f5f5f5;
}
.iui-treegrid-block-top-left .iui-treegrid-column-header, 
.iui-treegrid-block-top-left .iui-treegrid-column-footer, 
.iui-treegrid-block-top-right .iui-treegrid-column-header, 
.iui-treegrid-block-top-right .iui-treegrid-column-footer {
	background-color: #f0f0f0;
	border: thin solid #d5d5d5 !important;
    color: #000000;
}
.iui-treegrid-block-top-left .iui-treegrid-column-header-hovered, 
.iui-treegrid-block-top-right .iui-treegrid-column-header-hovered {
	background-color: #3174b0;
	border: thin solid #2b6599 !important;
    color: #000000;
}
.iui-treegrid-block-top-left .iui-treegrid-column-header-selected, 
.iui-treegrid-block-top-right .iui-treegrid-column-header-selected {
	background-color: #1a3e5e;
	border: thin solid #102538 !important;
    color: #ffffff;
}

.iui-treegrid-column-header, .iui-treegrid-column-footer
{
	color: black;
}
.iui-treegrid-column-header
{
	border-bottom: thin solid #d5d5d5 !important;
}
.iui-treegrid-column-footer
{
	border-top: thin solid #d5d5d5 !important;
}
.iui-treegrid-column-header-hovered, .iui-treegrid-column-header-selected, .iui-treegrid-row-hovered, .iui-treegrid-row-selected
{
    animation-name: none;
}
.iui-treegrid-column-header-selected
{
	color: white !important;
}
.iui-treegrid-row-cell:first-child
{
	border-left: thin solid #d5d5d5;
}
.iui-treegrid-row-cell:last-child
{
	border-right: thin solid #d5d5d5;
}
.iui-treegrid-lines-horizontal:not(:last-child)
{
	border-left-color: transparent;
	border-right-color: transparent;
	border-bottom-color: #d5d5d5;
}
.iui-treegrid-lines-horizontal:last-child
{
	border-left-color: transparent;
	border-bottom-color: #d5d5d5;
}
.iui-treegrid-lines-vertical
{
	border-right-color: #d5d5d5;
}
.iui-treegrid-lines-both
{
	border-right-color: #d5d5d5;
	border-bottom-color: #d5d5d5;
}
.iui-treegrid-expand-box-open
{
	background: url(../../icons/expand.ico) no-repeat 0 0;
}
.iui-treegrid-expand-box-open:hover
{
	background: url(../../icons/expand.ico) no-repeat 0 0;
}
.iui-treegrid-expand-box-close
{
	background: url(../../icons/collapse.ico) no-repeat 0 0;
}
.iui-treegrid-expand-box-close:hover
{
	background: url(../../icons/collapse.ico) no-repeat 0 0;
}
.iui-treegrid-row, .iui-treegrid-row-fixed {
	color: black;
}


.iui-treegrid
{
	border: thin solid #dadada;
}
.iui-treegrid-column-header, .iui-treegrid-column-footer
{
	background-color: #f0f0f0;
	border-color: #d5d5d5;
}
.iui-treegrid-column-header-hovered,
.iui-treegrid-block-top-left .iui-treegrid-column-header-hovered, 
.iui-treegrid-block-top-right .iui-treegrid-column-header-hovered
{
	background-color: #3174b0;
	border-color: #2b6599;
}
.iui-treegrid-column-header-selected,
.iui-treegrid-block-top-left .iui-treegrid-column-header-selected, 
.iui-treegrid-block-top-right .iui-treegrid-column-header-selected
{
	background-color: #1a3e5e;
	border: thin solid #102538;
	color: white;
}
.iui-treegrid-row-hovered
{
	background-color: #a6c9e6;
}
.iui-treegrid-row-selected
{
	background-color: #428bca;
	color: white;
}
.iui-treegrid-column-animate-select
{
	background: #3174b0;
}
.iui-treegrid-row-animate-select
{
	background: #a6c9e6;
}