.iui-treelist
{
	background: #efefef;
    color: #323232;
	overflow: hidden;
	padding: 1px;
	position: relative;
	cursor: default;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.iui-treelist-header
{
	background: #cecece;
	padding: 7px;
    position: relative;
	margin: -1px;
    z-index: 2;
}
.iui-treelist-header-title
{
    margin: 0 0 0 5px;
}
.iui-treelist-block
{
}
.iui-treelist-block ul {
    list-style-type: none;
    margin: 0;
    padding: 1px 0;
    position: absolute;
}

.iui-treelistitem
{
	margin: 0;
	padding: 5px;
}
.expand-icons
{
    background: url(../icons/expandbox-icons.png) no-repeat 0 0;
    display: inline-block;
    overflow: hidden;
    padding: 0;
    margin: -3px 0 0 -4px;
    width: 16px;
    height: 16px;
    vertical-align: middle;
}
.next
{
    background-position: 0 0;
}
.back
{
    background-position: 0 -32px;
}
.iui-treelistitem-hovered
{
	background: #e5e5e5;
}
.iui-treelistitem-selected
{
    background: #d9d9d9;
}

.iui-treelistgroup {
    background: #f5f5f5;
    padding: 5px;
    font-weight: bold;
}

.iui-treelistgroup div:focus, .iui-treelistitem div:focus
{
    outline: none !important;
}


/* Disabled State */
.iui-treelist-disabled div, .iui-treelist-disabled ul
{
    opacity: 0.75;
    pointer-events: none;
}


.iui-treelist-scroll {
    opacity: 0.25;
    z-index: 3;
}
.iui-treelist-scroll-available {
    animation-name: treelist-scroll-animate-enter;
    animation-delay: 0s;
    animation-direction: normal;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-play-state: running;
    animation-timing-function: linear; 
}
@keyframes treelist-scroll-animate-enter
{
    0% { opacity: 0.25; }
    100% { opacity: 1; }
}
.iui-treelist-scroll-not-available {
    animation-name: treelist-scroll-animate-leave;
    animation-delay: 0s;
    animation-direction: normal;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-play-state: running;
    animation-timing-function: linear; 
}
@keyframes treelist-scroll-animate-leave
{
    0% { opacity: 1; }
    100% { opacity: 0.25; }
}

/* Animation */
.iui-treelistitem-animate
{
    border: 0 solid transparent;
    background-color: transparent;
    box-shadow: none;
    list-style-type: none;
    overflow: hidden;
    position: relative;
}
.iui-treelistitem-animate-enter
{
    animation-name: listitem-animate-enter;
    animation-delay: 0s;
    animation-direction: normal;
    animation-duration: 0.175s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-play-state: running;
    animation-timing-function: linear; 
}
@keyframes listitem-animate-enter
{
    0% {       
        border: thin solid transparent;
        box-shadow: none;
    }
    100% { 
        border: thin solid #242424;
        box-shadow: 2px 3px 3px #cecece;
    }
}
.iui-treelistitem-animate-select-block
{
    display: block;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
}
.iui-treelist-header-animate-select, .iui-treelistitem-animate-select
{
    background: #f5f5f5;
    display: block;
    left: 0;
    position: absolute;
    opacity: 0;
    top: 0;
}
.iui-treelistitem-animate-select-left
{
    animation-name: listitem-animate-select-left;
    animation-delay: 0s;
    animation-direction: normal;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-play-state: running;
    animation-timing-function: linear; 
}
@keyframes listitem-animate-select-left
{
    0% {       
        left: 100%;
        opacity: 0.5;
        width: 0;
    }
    100% { 
        left: 0;
        opacity: 0;
        width: 100%;
    }
}
.iui-treelistitem-animate-select-right
{
    animation-name: listitem-animate-select-right;
    animation-delay: 0s;
    animation-direction: normal;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-play-state: running;
    animation-timing-function: linear; 
}
@keyframes listitem-animate-select-right
{
    0% {       
        width: 0;
        opacity: 0.5;
    }
    100% { 
        width: 100%;
        opacity: 0;
    }
}


