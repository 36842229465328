.iui-scrollbar-vertical
{
	cursor: default;
	position: absolute;
	top: 0;
	margin:0;
	padding: 2px;
	width: 11px;
	background-color: #f5f5f5;
	border-left: thin solid #f5f5f5;
	z-index: 99;
}
/*.iui-scrollbar-vertical:hover
{
	background-color: #eaeaea;
	border-left: thin solid #dadada;
}*/
.iui-scroll-button-thumb-vertical
{
	background-color: #d5d5d5;
	border: thin solid #c5c5c5;
	border-radius: 2px;
	position: absolute;
	top: 2px;
	width: 9px;
	z-index: 99;
}
.iui-scroll-button-thumb-vertical:hover
{
	background-color: #c5c5c5;
	border-color: #a1a1a1;
}
.iui-scrollbar-horizontal
{
	cursor: default;
	position: absolute;
	bottom: 0;
	margin:0;
	padding: 2px;
	height: 11px;
	background-color: #f5f5f5;
	border-top: thin solid #f5f5f5;
	z-index: 99;
}
/*.iui-scrollbar-horizontal:hover
{
	background-color: #eaeaea;
	border-top: thin solid #dadada;
}*/
.iui-scroll-button-thumb-horizontal
{
	background-color: #d5d5d5;
	border: thin solid #c5c5c5;
	border-radius: 2px;
	position: absolute;
	top: 2px;
	height: 9px;
	z-index: 99;
}
.iui-scroll-button-thumb-horizontal:hover
{
	background-color: #c5c5c5;
	border-color: #a1a1a1;
}
.iui-scrollbar-corner
{
	position: absolute;
	margin: 0;
	padding: 0;
	width: 15px;
	height: 15px;
	background-color: #f5f5f5;
	z-index: 99;
}
.iui-scroll-marker
{
	background: -webkit-linear-gradient(white, #eaeaea);
	background:    -moz-linear-gradient(white, #eaeaea);
	background:     -ms-linear-gradient(white, #eaeaea);
	background:      -o-linear-gradient(white, #eaeaea);
    background :         linear-gradient(white, #eaeaea);

    background-color: #eaeaea;
	border: thin solid #696969;
	border-radius: 3px;
	padding: 2px 5px;
	position: absolute;
	z-index: 99;
}
.iui-scroll-marker span
{
	display: block;
	margin: 0;
	max-width: 120px;
	overflow: hidden;
	padding: 0;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.iui-scroll-marker::before
{
	content: "";
	border: 4px solid #eaeaea;
	border-color: transparent transparent transparent #242424;
	position: absolute;
	top: 8px;
	right: -9px;

}
.iui-scroll-marker::after
{
	content: "";
	border: 4px solid #eaeaea;
	border-color: transparent transparent transparent #eaeaea;
	position: absolute;
	top: 8px;
	right: -8px;
}

/* Disabled State */
.iui-scrollbar-vertical-disabled div, .iui-scrollbar-horizontal-disabled div
{
	opacity: 0.75;
	pointer-events: none;
}

