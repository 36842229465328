.iui-groupbox
{
    color: black;
	cursor: default;
	margin: 2px 0;
	overflow: hidden;
	padding: 0;
	position: relative;
}
.iui-groupbox-header-block
{
	margin: 0;
	padding: 0;
	position: relative;
}
/* Normal State */
.iui-groupbox-header
{
	background-color: #e5e5e5;
	border: thin solid #cecece;
	padding: 3px;
	margin: 0;
	position: relative;
}
/* Hover State */
.iui-groupbox-header-hovered
{
	background-color: #f5f5f5;
	border: thin solid #e5e5e5;
	padding: 3px;
	margin: 0;
	position: relative;
}
/* Selected State */
.iui-groupbox-header-selected
{
	background-color: #bfbfbf;
	border: thin solid #a1a1a1;
	color: white;
	padding: 3px;
	margin: 0;
	position: relative;
}
.iui-groupbox-content
{
	background-color: #ffffff;
    border: thin solid #bbbbbb;
	margin: 0;
}
/* Disabled State */
.iui-groupbox-content-disabled
{
	border-color: gray;
	color: gray;
}
.iui-groupbox-header-marker-down::before
{
	content: "";
	border: 5px solid #ffffff;
	border-color:  transparent transparent #ffffff transparent;
	position: absolute;
	bottom: -1px;
	left: 5px;
	width: 0;
	height: 0;
	z-index: 2;

	animation-name: marker;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.75s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
.iui-groupbox-header-marker-down::after
{
	content: "";
	border: 5px solid transparent;
	border-color: transparent transparent transparent transparent;
	position: absolute;
	bottom: -1px;
	left: 5px;
	width: 0;
	height: 0;
}
@keyframes marker
{
	0% { bottom: -10px; }
	50% { bottom: -5px; }
	100% { bottom: -1px; }
}
@keyframes marker2
{
	0% { left: -10px; }
	50% { left: 2px; }
	100% { left: 5px; }
}

/* Animations */
.iui-groupbox-header-expand-box
{
	background: #484848 !important;
}


/* Disabled State */
.iui-groupbox-disabled div
{
	opacity: 0.75;
	pointer-events: none;
}

/* Animation */
.iui-groupbox-header-animate-select-block
{
	display: block;
	pointer-events: none;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 9;
}
.iui-groupbox-header-animate-select
{
	background: #f5f5f5;
	display: block;
	left: 0;
	position: absolute;
	opacity: 0;
	top: 0;
}
.iui-groupbox-header-animate-select-left
{
	animation-name: groupbox-header-animate-select-left;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes groupbox-header-animate-select-left
{
	0% { 	   
	    left: 100%;
	    opacity: 0.5;
	    width: 0;
	}
	100% { 
	    left: 0;
	    opacity: 0;
	    width: 100%;
	}
}
.iui-groupbox-header-animate-select-right
{
	animation-name: groupbox-header-animate-select-right;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes groupbox-header-animate-select-right
{
	0% { 	   
	    width: 0;
	    opacity: 0.5;
	}
	100% { 
	    width: 100%;
	    opacity: 0;
	}
}

