.iui-listbox
{
	background: white;
	border: thin solid #cecece;
	overflow: auto;
	padding: 1px;
	position: relative;
	cursor: default;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.iui-listbox li {
	display: block;
}

.iui-listbox-block-hover, .iui-listbox-block-select {
    background: transparent;
    border: thin solid #cecece;
    box-shadow: 2px 3px 3px #e5e5e5;
    pointer-events: none;
    position: absolute;
    z-index: 6;
}
.iui-listbox-block-hover > div, .iui-listbox-block-select > div {
    background: #f5f5f5;
    pointer-events: auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
}

/* Disabled State */
.iui-listbox-disabled div, .iui-listbox-disabled ul
{
	opacity: 0.75;
	pointer-events: none;
}
