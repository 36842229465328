.iui-contextmenu
{
    background-color: #fefefe;
	border: solid thin #dedede;
}
.iui-contextmenu .iui-menuitem-block
{
	background: #f5f5f5;
	border: solid thin #dedede;
}
.iui-contextmenu .iui-menuitem-root
{
	border-color: transparent;
	color: black;
}
.iui-contextmenu .iui-menuitem-root-hovered, .iui-contextmenu .iui-menuitem-root-selected
{
    animation-name: none;
	background-color: #ddeeff !important;
	border: solid thin #b3d9ff !important;
	color: black;
}
