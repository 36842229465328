@import 'integralui.css';

.iui-treeview
{
    background: white;
	border: thin solid #cecece;
    cursor: default;
	overflow: hidden;
	padding: 1px;
    position: relative;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.iui-treeview-item-empty-content
{
    background: #f5f5f5;
    margin: 0;
}

.iui-treeview-block-hover, .iui-treeview-block-select {
    background: transparent;
    border: thin solid #cecece;
    box-shadow: 2px 3px 3px #e5e5e5;
    pointer-events: none;
    position: absolute;
    z-index: 6;
}
.iui-treeview-block-hover > div, .iui-treeview-block-select > div {
    background: #f5f5f5;
    pointer-events: auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
}

/* Disabled State */
.iui-treeview-disabled div, .iui-treeview-disabled ul
{
    opacity: 0.75;
    pointer-events: none;
}
