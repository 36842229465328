.iui-tab-header
{
	background-color: #83a6e7;
	border: thin solid #2455b0;
	color: white;
}
.iui-tab-header-hovered
{
    animation-name: none;
	background-color: #d2dff7;
	border: solid thin gray;
	color: black;
}
.iui-tab-header-selected
{
	background-color: white;
	border: solid thin gray;
	color: black;
}
.iui-tab-content
{
	background: white;
	border-color: gray;
}
.iui-tab-selected-top
{
	border-bottom-color: white;
}
.iui-tab-selected-right
{
	border-left-color: white;
}
.iui-tab-selected-bottom
{
	border-top-color: white;
}
.iui-tab-selected-left
{
	border-right-color: white;
}

