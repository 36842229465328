.iui-button
{
	background: #e5e5e5;
    border: thin solid #cecece;
    border-radius: 3px;
    color: black;
	cursor: default;
	display: block;
	overflow: hidden;
	padding: 3px;
	position: relative;
	
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.iui-button-hovered
{
	background: #efefef;
}

.iui-button-selected
{
    background: #d5d5d5;
    border-color: #bebebe;
}

/* Disabled State */
.iui-button-disabled
{
	opacity: 0.75;
	pointer-events: none;
}
