.iui-rating
{
	background: white;
	border: thin solid #cecece;
	border-radius: 3px;
    color: black;
	cursor: default;
    height: 16px;
	margin: 0;
	overflow: hidden;
	padding: 1px;
	position: relative;
}
.iui-rating-content
{
    background-image: url(../icons/star-empty.ico);
    background-repeat: repeat-x;
    height: 16px;
}
.iui-rating-value
{
    background-image: url(../icons/star-full.ico);
    background-repeat: repeat-x;
    height: 16px;
}


/* Disabled State */
.iui-rating-disabled div
{
	opacity: 0.4;
	pointer-events: none;
}


