/* DisplayMode - LeftRight */
.iui-listscroller-leftright-buttons
{
	background: #6791e1;
	border: thin solid #5f8bde;
}
.iui-listscroller-leftright-buttons:hover
{
	background: #83a6e7;
	border: thin solid #5f8bde;
}

/* DisplayMode - UpDown */
.iui-listscroller-updown-buttons
{
	background: #6791e1;
	border: thin solid #5f8bde;
}
.iui-listscroller-updown-buttons:hover
{
	background: #83a6e7;
	border: thin solid #5f8bde;
}



