.iui-toolbar
{
    background: #fafafa;
	border: thin solid #cecece;
    cursor: default;
	overflow: hidden;
    padding: 3px;
    position: relative;

	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}

