.iui-item
{
	border: thin solid transparent;
}
.iui-item-label
{
	border: thin solid transparent;
	display: inline-block;
	padding: 2px;
}
.iui-item-icon
{

}
.iui-item-icon-url
{
	vertical-align: middle;
}

/* Disabled State */
.iui-item-disabled div
{
	opacity: 0.75;
	pointer-events: none;
}

.iui-item-content
{
	overflow: hidden;
	position: relative;
}


/* Normal State */
.iui-header
{
	overflow: hidden;
	position: relative;
}
.iui-header-move-marker
{
	border: thin solid red;
	display: inline-block;
	width: 6px;
	height: 16px;
	vertical-align: middle;
}
/* Hover State */
.iui-header-hovered
{
	background-color: #cccccc;
}
/* Selected State */
.iui-header-selected
{
	background-color: #aaaaaa;
	border-color: #808080;
	color: white;
}
/* Disabled State */
.iui-header-disabled
{
	opacity: 0.75;
	pointer-events: none;
}
.iui-header-expand-box
{
	display: inline-block;
	margin: 5px;
	position: absolute;
	right: 0;
	top: 0;
	width: 16px;
	height: 16px;
}
.iui-header-expand-box-vertical
{
	background: white;
	display: block;
	position: absolute;
	left: 7px;
	width: 2px;
	height: 16px;
}
.iui-header-expand-box-vertical-expand
{
	animation-name: vertical-expand;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.4s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes vertical-expand
{
	0% { transform: rotate(-90deg); }
	100% { transform: rotate(0deg); }
}
.iui-header-expand-box-vertical-collapse
{
	animation-name: vertical-collapse;
	animation-delay: 0.2s;
	animation-direction: normal;
	animation-duration: 0.4s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes vertical-collapse
{
	0% { transform: rotate(0deg); }
	100% { transform: rotate(-90deg); }
}


.iui-header-expand-box-horizontal
{
	background: white;
	display: block;
	position: absolute;
	top: 7px;
	width: 16px;
	height: 2px;
}
.iui-header-expand-box-horizontal-expand
{
	animation-name: horizontal-expand;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes horizontal-expand
{
	0% { max-width: 0; left: 7px; }
	80% { max-width: 4px; left: 2px; }
	100% { max-width: 16px; left: 0; }
}
.iui-header-expand-box-horizontal-collapse
{
	animation-name: horizontal-collapse;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.4s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes horizontal-collapse
{
	0% { max-width: 16px; left: 0; }
	100% { max-width: 0; left: 7px; }
}



.iui-header-expand-box-arrow
{
	display: inline-block;
	float2: right;
	margin: 5px;
	position: relative;
	width: 16px;
	height: 16px;

	position: absolute;
	right: 0;
	top: 2px;
}
.iui-header-expand-box-arrow-bottom-left
{
	background: white;
	display: block;
	position: absolute;
	top: 8px;
	left: -3px;
	width: 10px;
	height: 2px;
	transform: rotate(45deg);
}
.iui-header-expand-box-arrow-bottom-left-expand
{
	animation-name: arrow-bottom-left-expand;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.4s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes arrow-bottom-left-expand
{
	0% { max-width: 0; left: 3px; }
	60% { max-width: 0; left: 3px; }
	100% { max-width: 10px; left: -3px;}
}
.iui-header-expand-box-arrow-bottom-left-collapse
{
	animation-name: arrow-bottom-left-collapse;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.4s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes arrow-bottom-left-collapse
{
	0% { max-width: 10px; left: -3px; }
	100% { max-width: 0; left: 3px; }
}

.iui-header-expand-box-arrow-bottom-right
{
	background: white;
	display: block;
	position: absolute;
	top: 8px;
	left: 3px;
	width: 10px;
	height: 2px;
	transform: rotate(-45deg);
}
.iui-header-expand-box-arrow-bottom-right-expand
{
	animation-name: arrow-bottom-right-expand;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.4s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes arrow-bottom-right-expand
{
	0% { max-width: 0; }
	60% { max-width: 0; }
	100% { max-width: 10px; }
}
.iui-header-expand-box-arrow-bottom-right-collapse
{
	animation-name: arrow-bottom-right-collapse;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.4s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes arrow-bottom-right-collapse
{
	0% { max-width: 10px; }
	100% { max-width: 0; }
}

.iui-header-expand-box-arrow-vertical
{
	background: white;
	display: block;
	position: absolute;
	top: 0;
	left: 3px;
	width: 2px;
	height: 10px;
}
.iui-header-expand-box-arrow-vertical-expand
{
	animation-name: arrow-vertical-expand;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.4s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes arrow-vertical-expand
{
	0% { max-height: 10px; top: 0; }
	100% { max-height: 0; top: 10px; }
}
.iui-header-expand-box-arrow-vertical-collapse
{
	animation-name: arrow-vertical-collapse;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.4s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes arrow-vertical-collapse
{
	0% { max-height: 0; top: 10px;}
	100% { max-height: 10px; top: 0; }
}

.iui-header-expand-box-arrow-top-left
{
	background: white;
	display: block;
	position: absolute;
	top: 8px;
	left: -3px;
	width: 10px;
	height: 2px;
	transform: rotate(135deg);
}
.iui-header-expand-box-arrow-top-left-expand
{
	animation-name: arrow-top-left-expand;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.4s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes arrow-top-left-expand
{
	0% { max-width: 0; left: 3px; }
	60% { max-width: 0; left: 3px; }
	100% { max-width: 10px; left: -3px; }
}
.iui-header-expand-box-arrow-top-left-collapse
{
	animation-name: arrow-top-left-collapse;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.4s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes arrow-top-left-collapse
{
	0% { max-width: 10px; left: -3px; }
	100% { max-width: 0; left: 3px; }
}

.iui-header-expand-box-arrow-top-right
{
	background: white;
	display: block;
	position: absolute;
	top: 8px;
	left: 3px;
	width: 10px;
	height: 2px;
	transform: rotate(-135deg);
}
.iui-header-expand-box-arrow-top-right-expand
{
	animation-name: arrow-top-right-expand;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.4s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes arrow-top-right-expand
{
	0% { max-width: 0; }
	60% { max-width: 0; }
	100% { max-width: 10px; }
}
.iui-header-expand-box-arrow-top-right-collapse
{
	animation-name: arrow-top-right-collapse;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.4s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
	animation-timing-function: linear; 
}
@keyframes arrow-top-right-collapse
{
	0% { max-width: 10px; }
	100% { max-width: 0; }
}

.iui-load-window
{
	background: white;
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	opacity: 1;
	z-index: 9999999;
}
.iui-load-block
{
	background: #e5e5e5;
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 999;
}
.iui-load-block-progress
{
	background: #0080c0;
	width: 0;
	height: 5px;
}

.iui-popup
{
	/*box-shadow: 2px 3px 3px #cecece;*/
}	    
.iui-popup:focus {
    outline: none !important;
}

.iui-list
{
	background: white;
	border: thin solid #cecece;
	overflow: auto;
	padding: 1px;
	position: relative;
	cursor: default;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.iui-list ul
{
	margin: 0;
	padding: 0;
}

/* Disabled State */
.iui-list-disabled div, .iui-list-disabled ul
{
	opacity: 0.75;
	pointer-events: none;
}
.iui-list li
{
    border: 2px solid transparent;
    color: black;
    list-style-type: none;
    padding: 2px;
    overflow: hidden;
    white-space: nowrap;
}
.iui-list li:hover
{
	background-color: #f5f5f5;
	border: 2px solid #efefef;
}
.iui-list-item-selected {
	background-color: #e5e5e5;
}
