.iui-treeitem-hovered, .iui-treeitem-content-hovered
{
    animation-name: none;
    background-color: #a6c9e6;
    border: 2px solid #97c0e1;
}
.iui-treeitem-selected, .iui-treeitem-content-selected
{
    animation-name: none;
    background-color: #428bca;
    border: 2px solid #357ebd;
    color: white;
}
.iui-treeitem-expand-box
{
    background: url(../../icons/expandbox-icons.png) no-repeat 0 -48px;
}
.iui-treeitem-expand-box-open, .iui-treeitem-expand-box-open:hover
{
    background-position: 0 -16px;
}
.iui-treeitem-expand-box-close, .iui-treeitem-expand-box-close:hover
{
    background-position: 0 0;
}
.iui-treeitem-expand-box-open-animate
{
    background-position: 0 0;
}
.iui-treeitem-expand-box-close-animate
{
    background-position: 0 0;
}
