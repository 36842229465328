/* Normal State */
.iui-groupbox-header
{
	background-color: #6791e1;
	border: thin solid #5f8bde;
}
/* Hover State */
.iui-groupbox-header-hovered
{
    animation-name: none;
	background-color: #83a6e7;
	border: thin solid #5f8bde;
}
/* Selected State */
.iui-groupbox-header-selected
{
    animation-name: none;
	background-color: #2455b0;
	border: thin solid #1e4691;
	color: white;
}
.iui-groupbox-content
{
	background-color: #f0f8ff;
	border: solid thin #5f8bde;
}

.iui-groupbox-header-selected .iui-groupbox-header-expand-box
{
	background: #ffffff !important;
}

.iui-groupbox-header-animate-select
{
	background: #83a6e7;
}