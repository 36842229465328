
*{
  font-family: 'Open Sans','SemiBold';
  font-size: 16px;
  /* text-align: left; */
font-family: normal normal 600 16px/22px Open Sans;
letter-spacing: 0px;
/* color:#222222; */
  
}
body{
  font-family: Open Sans;
  font-size: 14px;
  /* height:1500px; */
}
.Home-page .bg{
  background-color: #1F3978 !important;
}
.logo{
  width: 66px;
}
.logo img{
  width: 100%;
}
.Home-page .bg .navbar-nav li a{
  color:#030303;
  font-size: 12px;
}
.nav-user-icon{
font-size: 22px !important;
  color: #e6800e;
  border-radius: 50px;
  background: #fff !important;
  margin-right: -8px;
}
.navbar-toggler {
  padding: .25rem .5rem !important;
  font-size: 0.85rem;
  line-height: 1;
  background-color: #d8dadf !important;
  border: 1px solid transparent;
  border-radius: .25rem;
}
.navbar-toggler-icon {
  display: block;
  width: 1.5em;
  height: 0.5em !important;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
  color: #1f3978 !important;
}
/*new*/
.nav-link{
  color:#A4AFB7 !important;
}
.dropdown-menu {      
left: -88px !important;  
}
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: .8em;
}

.dropdown-submenu .dropdown-menu {
  top: 33px;   
}
.dropdown-icons{
  font-size: 16px !important;
  color: #d37025;
}
/*footer*/

.logo-ft{
  width: 100px;
}
.logo-ft img{
  width: 100%;
}
.ft-text{
  color:#B1C1E0; font-size: 12px;
}
.ft-pwr{
color:#f9f9f9; font-size: 12px;
}
.ft-bg{
  padding: 3px 17px 0px 15px !important;
  background-color: #000000;
}


.td-clr{
  color: #909090;
}
.portal-Branch .branch-btn {
  border: none;
  padding: 1px;
  color: #e6800e;
}
.star-style{
color: #e5800f;font-size: 8px
}
/*formstyle*/
body{
font-family: Open Sans;
background: #f5f6fa;
}
.portal-form .col-form-label {
  font-size: 14px;
}

input::placeholder {
 font-size: 14px; 
 padding-right: 10px;
}
.portal-form .form-control:focus {
  color: #495057;
  background-color: #fff;
   border-color: #c75249 !important; 
  outline: 0;
   box-shadow: unset !important; 
}
.portal-form .form-control {
  color: #495057;
  background-color: unset !important;
  border: none;
    /* border-left: 2px solid #e68820 !important;*/
     border-left: 2px solid #8f9cbb !important;
     border-radius: unset !important;
     height: 28px;
     font-size: 14px;
}
input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: #fff !important;
  background-image: none !important;
  color: -internal-light-dark(black, white) !important;
}
.portal-form .border{
border: 1px solid #d0d2d4!important;
  border-radius: 3px;
}
.btn-clear{

border: 1px solid #1f3978;
  padding: 2px 16px 2px 16px;
  color: #1f3978;
}
.btn-save{
background: #1f3978;
  padding: 2px 28px 2px 28px;
  color: #fff;
}
input:placeholder-shown {
 background-color:  #ffffff!important;
}


.portal-form .tab-content{
background-color: #fff;
/* padding: 12px 29px 12px 9px; */
}
.portal-form table td span{
background: #f5f6fa;
  border-radius: 6px;
}

.lab-wd{
width: 164px;
}
.in-wd{
width: 83.9%;
margin-left: 12px;
}

.portal-form .fn-14{
font-size: 14px;
}
.portal-form h5{
color: #8f9cbb;
 }
.portal-form h4{
text-align: center;
  font-weight: bolder;
  padding-bottom: 30px
}

.clear{
clear: both;
}
.form-group {
margin-bottom: 1rem;
white-space: nowrap;
}
/*card*/
.h4-style{
color: #e5800f;
  background-color: rgb(194 194 194 / 6%);
}
.portal-form .card-body { 
padding: 6px 25px 0px 20px !important;
}

.portal-form .btn:hover {
color: #f2f4f5;
text-decoration: none;
}



@media (max-width: 767px)
{
 .portal-form .pr-3{
    padding-right: 0px !important;}
   .portal-form .pl-4{
      padding-left: 0px !important;
    }
    .portal-form .text-right {
      text-align: unset !important;
  }
  .portal-form .in-wd {
    width: 100%;
    margin-left: 1px;
}
.portal-form .lab-wd{
  padding-left: 10px;
}
.portal-form .card-body {
  padding: 6px 17px 0px 16px !important;
}
}
@media  (min-width: 768px) and (max-width: 991px)
{
 .portal-form .pr-3{
    padding-right: 0px !important;}
    .portal-form .pl-4{
      padding-left: 0px !important;
    }
    .portal-form .text-right {
      text-align: unset !important;
  }
  .portal-form .lab-wd{
    padding-left: 10px;
  }
  .portal-form .in-wd {
    width: 74%;
    margin-left: 10px;
  }
  .portal-form .form-group {
    white-space: inherit;
  }
  .portal-form .card-body {
    padding: 6px 25px 0px 24px !important;
}
}




@media (min-width: 992px) and (max-width: 1024px){
.in-wd {
 width: 83%;
  margin-left: 10px;
}
.lab-wd {
width: 146px;
}
}



/*profile*/


.profile {
margin: 20px 0;
}
.profile-userpic img {
float: none;
margin: 0 auto;
width: 50%;
height: 50%;

border-radius: 18% !important;
box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.profile-usertitle {
text-align: center;
margin-top: 20px;
}
.profile .btn:hover {
color: #ffffff;
text-decoration: none;
}

.form-control:focus { 
box-shadow: unset !important;
}
.profile .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #A4AFB7 !important;
opacity: 0.5; /* Firefox */
}
/*setting*/

.setting .card {
text-align: center;
background: transparent;
  border: none;
}
.setting .card button{
background: #fff;
  border: none;
}
.setting .card button:focus {
outline: none !important; 
}
.card-title {
color: #1f3978 !important;
  font-weight: 800;

}












/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: 'Roboto', sans-serif; }


/* test */


body{
font-family: Open Sans;
font-size: 14px;
height:1500px;
}
.Home-page .bg{
background-color: #1F3978;
}
.logo{
width: 66px;
}
.logo img{
width: 100%;
}
.Home-page .bg .navbar-nav li a{
color:#030303;
font-size: 12px;
}
.nav-user-icon{
font-size: 22px !important;
color: #e6800e;
border-radius: 50px;
background: #fff !important;
margin-right: -8px;
}
.navbar-toggler {
padding: .25rem .5rem !important;
font-size: 0.85rem;
line-height: 1;
background-color: #d8dadf !important;
border: 1px solid transparent;
border-radius: .25rem;
}
.navbar-toggler-icon {
display: block;
width: 1.5em;
height: 0.5em !important;
/* vertical-align: middle; */
content: "";
background: no-repeat center center;
background-size: 100% 100%;
color: #1f3978 !important;
}
/*new*/
.nav-link{
color:#A4AFB7 !important;
}
.dropdown-menu {      
left: -88px !important;  
}
.dropdown-submenu {
position: relative;
}

.dropdown-submenu a::after {
transform: rotate(-90deg);
position: absolute;
right: 6px;
top: .8em;
}

.dropdown-submenu .dropdown-menu {
top: 33px;   
}
.dropdown-icons{
font-size: 16px !important;
color: #d37025;
}
/*footer*/

.logo-ft{
width: 100px;
}
.logo-ft img{
width: 100%;
}
.ft-text{
color:#B1C1E0; font-size: 12px;
}
.ft-pwr{
color:#f9f9f9; font-size: 12px;
}
.ft-bg{
padding: 3px 17px 0px 15px !important;
background-color: #000000;
}


.td-clr{
color: #909090;
}
.portal-Branch .branch-btn {
border: none;
padding: 1px;
color: #e6800e;
}
.star-style{
color: #e5800f;font-size: 8px
}
/*formstyle*/
body{
font-family: Open Sans;
background: #f5f6fa;
}
.portal-form .col-form-label {
font-size: 14px;
}

input::placeholder {
font-size: 14px; 
padding-right: 10px;
}
.portal-form .form-control:focus {
color: #495057;
background-color: #fff;
 border-color: #c75249 !important; 
outline: 0;
 box-shadow: unset !important; 
}
.portal-form .form-control {
color: #495057;
background-color: unset !important;
border: none;
  /* border-left: 2px solid #e68820 !important;*/
   border-left: 2px solid #8f9cbb !important;
   border-radius: unset !important;
   height: 28px;
   font-size: 14px;
}
input:-internal-autofill-selected {
appearance: menulist-button;
background-color: #fff !important;
background-image: none !important;
color: -internal-light-dark(black, white) !important;
}
.portal-form .border{
border: 1px solid #d0d2d4!important;
border-radius: 3px;
}
.btn-clear{

border: 1px solid #1f3978;
padding: 2px 16px 2px 16px;
color: #1f3978;
}
.btn-save{
background: #1f3978;
padding: 2px 28px 2px 28px;
color: #fff;
}
input:placeholder-shown {
background-color:  #ffffff!important;
}


.portal-form .tab-content{
background-color: #fff;
/* padding: 12px 29px 12px 9px; */
}
.portal-form table td span{
background: #f5f6fa;
border-radius: 6px;
}

.lab-wd{
width: 164px;
}
.in-wd{
width: 83.9%;
margin-left: 12px;
}

.portal-form .fn-14{
font-size: 14px;
}
.portal-form h5{
color: #8f9cbb;
}
.portal-form h4{
text-align: center;
font-weight: bolder;
padding-bottom: 30px
}

.clear{
clear: both;
}
.form-group {
margin-bottom: 1rem;
white-space: nowrap;
}
/*card*/
.h4-style{
color: #e5800f;
background-color: rgb(194 194 194 / 6%);
}
.portal-form .card-body { 
padding: 6px 25px 0px 20px !important;
}

.portal-form .btn:hover {
color: #f2f4f5;
text-decoration: none;
}



@media (max-width: 767px)
{
.portal-form .pr-3{
  padding-right: 0px !important;}
 .portal-form .pl-4{
    padding-left: 0px !important;
  }
  .portal-form .text-right {
    text-align: unset !important;
}
.portal-form .in-wd {
  width: 100%;
  margin-left: 1px;
}
.portal-form .lab-wd{
padding-left: 10px;
}
.portal-form .card-body {
padding: 6px 17px 0px 16px !important;
}
}
@media  (min-width: 768px) and (max-width: 991px)
{
.portal-form .pr-3{
  padding-right: 0px !important;}
  .portal-form .pl-4{
    padding-left: 0px !important;
  }
  .portal-form .text-right {
    text-align: unset !important;
}
.portal-form .lab-wd{
  padding-left: 10px;
}
.portal-form .in-wd {
  width: 74%;
  margin-left: 10px;
}
.portal-form .form-group {
  white-space: inherit;
}
.portal-form .card-body {
  padding: 6px 25px 0px 24px !important;
}
}




@media (min-width: 992px) and (max-width: 1024px){
.in-wd {
width: 83%;
margin-left: 10px;
}
.lab-wd {
width: 146px;
}
}



/*profile*/


.profile {
margin: 20px 0;
}
.profile-userpic img {
float: none;
margin: 0 auto;
width: 50%;
height: 50%;

border-radius: 18% !important;
box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.profile-usertitle {
text-align: center;
margin-top: 20px;
}
.profile .btn:hover {
color: #ffffff;
text-decoration: none;
}

.form-control:focus { 
box-shadow: unset !important;
}
.profile .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #A4AFB7 !important;
opacity: 0.5; /* Firefox */
}

/* .setting .card:hover{
background-color: #c75249;
} */
.setting .card button {
-webkit-transform: scale(1);
transform: scale(1);
-webkit-transition: .3s ease-in-out;
transition: .3s ease-in-out;
}
.setting .card button:hover img {
-webkit-transform: scale(1.3);
transform: scale(1.3);
}
.setting .card button{
background: none !important;
border: none;
}
.setting .card button:focus {
outline: none !important; 
}
.card-title {
color: #1f3978 !important;
font-size: 18px !important;
padding-top: 0px;
margin-left: 5% !important;
}


/*sass*/

.mat-stepper-horizontal {
margin-top: 8px;
}

.mat-form-field {
margin-top: 16px;
}

:host {
display: flex;
flex-direction: column;
align-items: flex-start;
}
.mat-step-icon .mat-icon {
font-size: 20px;
height: 16px;
width: 16px;
/* margin-top: 12px; */
color: #fff;
}
ng-star-inserted:focus{
background-color: #e5800f;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-radio-button ~ .mat-radio-button {
margin-left: 16px;
}

/*master-screen*/
.master-screen .mat-form-field {
display: block; 
}
.master-screen .mat-form-field-appearance-fill .mat-form-field-flex {
background-color: rgb(245 246 250);
}
.master-screen button{
border: none;
background: none;
margin-top: 41px;
color: #626264;
}
.master-screen .border-dark {
border-color: #8e8f91!important;
}

.master-screen .mat-h5, .mat-typography h5 {
font: none !important;
margin: 0 0 12px;
}
.master-screen input:placeholder-shown {
background-color: #f5f6fa!important;
}


/*new*/
.indsearch .searchbtn{
color: #a09992;border: 1px solid #ced4da;
}
.indsearch .btn:hover {
color: #ffff;
text-decoration: none;
background: #1f3978;
}
.indsearch .bg-col{
background-color: #1f397821;
height: 100px;
width: 105px;
border-radius: 50px;
}
/*new temp */
.temp-new .btn-tab-abv {
background: #ffff;
color: #222222;
width: 120px;
padding: 8px;
border: 1px solid white;
border-radius: 50px !important;
}
.temp-new .btn-add-abv {
background: #ffff;
color: #222222;
padding: 8px;
border: 1px solid white;  
}
.temp-new .btn-add-abv i{
color: #e6800e;
}

.btn-tab-act{
background: #E6800E;
color: #fff;
width: 120px;
padding: 8px;
border: 1px solid #E6800E;
border-radius: 50px !important;
}
.mat-tab-label, .mat-tab-link {
color: #777777;
}
.mat-tab-label, .mat-tab-link {
font-family: Open sans-serif; 
font-size: 16px;
}
.mat-tab-label.mat-tab-label-active { 
color:#E6800E; 

}

.mat-tab-group {
font-family: Roboto, "Helvetica Neue", sans-serif;
background: #fff;
}
.mat-tab-body-content {
background:#F5F6FA;
}


.custom-control-input:checked~.custom-control-label::before {
color: #fff;
border-color: #e57f0e;
background-color: #e47f0e;
}



.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
background-color: green !important;

}



/*24*/
.side ul{
display: block;
}
.tabs-left>li.active>a, .tabs-left>li.active>a:hover, .tabs-left>li.active>a:focus {
border-bottom-color: #ddd;
border-right-color: transparent;
color: #e6800e;
border-right: 3px solid #e6800e ;
}
.tabs-left>li>a{
color: #e6800e !important;


}
.tabs-left>li>a {
border-radius: 4px 0 0 4px;
margin-right: 0;
display: block;
color: #222222;
font-size: 14px;
}

.tab-head{
color: #1f397875;
font-weight: bold;
font-size: 19px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
color: #e6800e !important;
background-color: #fff;
border-color: #fff #fff #fff;
border-right: 2px solid #e6800e;

}
.temp-new .nav-link {
color: #222222 !important;
}
/*today*/
.Descr .mat-form-field-label {
top: 34px !important;    
}
.Descr .mat-form-field {
margin-top: 3px !important;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
background-color: #fff !important;
}
.mat-form-field-appearance-fill .mat-form-field-infix {
padding: 0px 0px 6px 0px !important;
}

.mat-form-field-infix { 
width: 450px !important;
}
.formfields .formfields2  .mat-form-field { 
margin-top: 2px !important;
}
.formfields .formfields3 .mat-form-field-infix {
width: 328px !important;
}
.formfields .formfields4 .mat-form-field-infix {
width: 328px !important;
}
.formfields .formfields5 .mat-form-field-infix {
width: 125px !important;
margin-top: 3px;
}

.temp-new form{
box-shadow: 0 0 4px 4px #e5e5e7;
padding: 30px 0px 30px 77px;
margin-top: 6%;
margin-bottom: 5%;
}

.loan  .mat-form-field-infix{
width: 385px !important;
}
.loan1  .mat-form-field-infix{
width: 385px !important;
}
.loan1 .mat-form-field {
margin-top: 3px !important;
}

.loan2  .mat-form-field-infix{
width: 365px !important;
}
.loan3  .mat-form-field-infix{
width: 381px !important;
margin-top: 4px;
}









@media  (min-width: 320px) and (max-width: 767px){
.mat-tab-label-container {
display: unset !important;
flex-grow: 1;
overflow: hidden;
z-index: 1;
}
.mat-form-field-infix {
width: 245px !important;
}
.formfields .mat-form-field-infix {
width: 223px  !important;
}
.formfields .formfields3 .mat-form-field-infix {
width: 209px !important;
}
.formfields .formfields4 .mat-form-field-infix {
width: 209px !important;
}
.formfields .formfields5 .mat-form-field-infix {
width: 233px !important;
margin-top: 6px;
}
.loan .mat-form-field-infix {
width: 241px !important;
}
.loan1 .mat-form-field-infix {
width: 241px !important;
}
.loan2 .mat-form-field-infix {
width: 241px !important;
}
.loan3 .mat-form-field-infix {
width: 241px !important;
}
body{
/* height:1500px;  */
}
}

@media  (min-width: 768px) and (max-width: 991px){
.mat-form-field-infix {
width: 430px !important;
}
.formfields .mat-form-field-infix {
width: 223px  !important;
}
.formfields .mat-form-field-infix {
width: 430px !important;
}
.formfields .formfields3 .mat-form-field-infix {
width: 407px !important;
}
.formfields .formfields4 .mat-form-field-infix {
width: 407px !important;
}
.formfields .formfields5 .mat-form-field-infix {
width: 430px !important;
margin-top: 6px;
}
.loan .mat-form-field-infix {
width: 241px !important;
}
.loan1 .mat-form-field-infix {
width: 241px !important;
}
.loan2 .mat-form-field-infix {
width: 223px !important;
}
.loan3 .mat-form-field-infix {
width: 241px !important;
}
}