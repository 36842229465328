.iui-tabstrip
{
	border: thin solid transparent;
	position: relative;
	color: black;
	cursor: default;
	overflow: hidden;
	
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
    -ms-user-select: none;
	user-select: none;
}
.iui-tabstrip-block
{
	display: inline-block;
	overflow: hidden;
	position: absolute;
	vertical-align: top;
}
.iui-tabstrip-block > ul
{
	position: absolute;
	list-style: none;
	list-style-type: none;
	margin: 0;
	padding: 0;
	white-space: nowrap;
}
.iui-tabstrip-content {
	background: white;
	border: thin solid #bcbcbc;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
}
.iui-tabstrip-line {
	background: #808080;
	display: none;
	left: 0;
	position: absolute;
	top: 0;
	z-index: 5;
}

.iui-tabstrip-scroll-inbound, .iui-tabstrip-scroll-outbound
{
	background: #ffffff;
	cursor: default;
	display: inline-block;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: absolute;
	z-index: 9;
}
.iui-tabstrip-scroll-border-bottom {
	border-bottom: thin solid #bcbcbc;
}
.iui-tabstrip-scroll-border-left {
	border-left: thin solid #bcbcbc;
}
.iui-tabstrip-scroll-border-right {
	border-right: thin solid #bcbcbc;
}
.iui-tabstrip-scroll-border-top {
	border-top: thin solid #bcbcbc;
}
.iui-tabstrip-scroll-button
{
	border: thin solid #808080;
	border-radius: 2px;
	display: inline-block;
	margin: 0;
	padding: 0;
	opacity: 0.6;
}
.iui-tabstrip-scroll-button:hover
{
	background: #fefefe;
	opacity: 1;
}
.iui-tabstrip-scroll-button-selected
{
	background: #ffffff;
	opacity: 1;
}
.iui-tabstrip-scroll-button span
{
	display: block;
	margin: 0;
	padding: 0;
	width: 24px;
	height: 24px;
}
.iui-tabstrip-scroll-button-left, .iui-tabstrip-scroll-button-right
{
	margin-top: 2px;
}
.iui-tabstrip-scroll-button-left span
{
    background-image: url(../icons/prev-24.png);
}
.iui-tabstrip-scroll-button-right span
{
    background-image: url(../icons/next-24.png);
}
.iui-tabstrip-scroll-button-up
{
	margin: 2px 0;
}
.iui-tabstrip-scroll-button-up, .iui-tabstrip-scroll-button-down
{
	display: block;
	width: 24px;
	height: 24px;
}
.iui-tabstrip-scroll-button-up span
{
    background-image: url(../icons/up-24.png);
}
.iui-tabstrip-scroll-button-down span
{
    background-image: url(../icons/down-24.png);
}

/* Disabled State */
.iui-tabstrip-disabled div
{
	opacity: 0.75;
	pointer-events: none;
}
