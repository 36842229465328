.iui-contextmenu
{
    background: #f5f5f5;
    border: solid thin #dedede;
    cursor: default;
    margin: 0;
	overflow: visible;
	padding: 1px;
    position: absolute;
    z-index: 999;
    
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;

    width: 150px;
}
.iui-contextmenu:focus {
    outline: none !important;
}
.iui-contextmenu ul
{
    margin: 0;
    padding: 0;
}
.iui-contextmenu .iui-menuitem-block
{
    width: 150px;
}