.iui-combobox
{
	background: white;
    color: black;
	cursor: default;
	margin: 2px 0;
	overflow: visible;
	padding: 0;
	position: relative;
}

/* ComboBox Header */
.iui-combobox-header
{
	border: thin solid #cecece;
	padding: 2px 0;
	white-space: nowrap;
}
.iui-combobox-header-hovered, .iui-combobox-header-selected
{
	background: transparent;
	border: thin solid #cecece;
	color: black;
	padding: 2px 0;
}
.iui-combobox-header-expand-box
{
	background: #484848 !important;
}

/* ComboBox DropDown Window */
.iui-combobox-dropdown
{
	background: white;
	border: thin solid #cecece;
	margin: 0;
	overflow-y: auto;
	overflow-x: hidden;
	position: absolute;
	z-index: 999;
}
.iui-combobox-dropdown:focus {
    outline: none !important;
}
.iui-combobox-dropdown .iui-item
{
    margin: 1px;
    padding: 1px;
}
.iui-combobox-dropdown .iui-item-hovered
{
	background-color: #f5f5f5;
	border: thin solid #efefef;
    margin: 1px;
    padding: 1px;
}
.iui-combobox-dropdown .iui-item-selected
{
	background-color: #e5e5e5;
	border: thin solid #dedede;
    margin: 1px;
    padding: 1px;
}

/* Disabled State */
.iui-combobox-disabled div
{
	opacity: 0.75;
	pointer-events: none;
}

